import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HorizontalScroll from 'components/HorizontalScroll';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { setMealPlan } from 'store/mealPlan';
import { setPlanParams } from 'store/planParams';
import { setCustomMealPlan } from 'store/customMealPlan';
import { setCustomPlanParams } from 'store/customPlanParams';
import { intersect } from 'lib';
import Rating from 'react-rating';
import moment from 'moment';
import ToolTip from 'components/ToolTip';
import noImage from 'assets/Coming-Soon.svg';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import LeftArrow from 'assets/left-arrow.png';
import RightArrow from 'assets/right-arrow.png';
import { setModal, clearModal } from '../../../../../store/modal';
import PaperButton from 'react-paper-button';
import { Select2 } from "select2-react-component"; 
import { spin, stop } from 'store/spinner';
import RatingModal from './RatingModal';
import DishDescription from 'components/DishDescription';
import Leaf from 'assets/leaf01.svg';
import caloriesIcon from 'assets/calories-ic.svg';
import LoveEmojiIcon from 'assets/emoji-ic01.svg';
import GoodEmojiIcon from 'assets/emoji-ic02.svg';
import OkEmojiIcon from 'assets/emoji-ic03.svg';
import BadEmojiIcon from 'assets/emoji-ic04.svg';
import CardEmojiIcon from 'assets/front.svg';

const animateTime = 100;  

class Day extends Component { 
  constructor(props) {
    super(props);
    const activeType = props.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity);
    const {stop} = props;
    stop();
    this.state = {
      xyz: false,
      sides: null,
      basicside: null,
      oilside: null,
      buyside: null,
      typeEdit: false,
      showinfo: [],
      dislikeproducts:[],
      allergyproducts:[],
      activeType,
      ratings:props.ratings,
      checkMealPlan: []
    };

  }

  saveRating(product_id, rating) {
    const {ratings}=this.state;
    this.props.socket.emit('profile', {type: 'getForDashboard', data: {
          'subtype': 'setDishRating', 
          'userId': this.props.user.user_id,
          'productId': product_id,
          'rating': rating,
        } 
    });
    if(ratings[product_id]!=undefined){
        ratings[product_id]=rating;
        let ratings1=ratings;
        this.setState({
          ratings:ratings1
        })
      }
    // var changes = 'dish rating for dish with id #'+product_id;
    // this.props.socket.emit('profile', {type: 'getForDashboard', data: {
    //       'subtype': 'alertMail', 
    //       'userId': this.props.user.user_id,
    //       'changes': changes,
    //     } 
    // });

  }

  sidesChange(value, date, dish_id, type, type_id) {
    const {clearModal} = this.props;
    
    this.setState({
      [type]: value
    });

    clearModal();
    if (type == 'buyside') {
      setTimeout(() => this.buySidesModal(date, dish_id, type_id), 10);
    } else {
      setTimeout(() => this.sidesModal(date, dish_id), 10);
    }
  }

  saveSides(sides, date, dish_id) {
    const {planParams, clearModal} = this.props;
    const {basicside, oilside} = this.state;
    var sides = [];
    
    if (basicside) {
      sides.push(basicside);
    }
    
    if (oilside) {
      sides.push(oilside);
    }
    
    this.setState({
      sides: sides
    });
    
    var data = {sides: sides, date: date, dishId: dish_id, orderId: planParams.id, userId: this.props.user.user_id}
    console.log(data);
    this.props.socket.emit('order', {type: 'saveUserSides', data: data});
    clearModal();
  }

  updateNotselectableProducts = (product_id,type) => {
    var {dislikeproducts,allergyproducts} = this.state;
    if(type=='allergy'){
      if (allergyproducts && allergyproducts.indexOf(product_id) == -1) {
        allergyproducts.push(product_id);
        this.setState({
          allergyproducts: allergyproducts, 
        });
      }
    }else if(type=='dislike'){
      if (dislikeproducts && dislikeproducts.indexOf(product_id) == -1) {
           dislikeproducts.push(product_id);
          this.setState({
            dislikeproducts: dislikeproducts, 
          });
      }

    }
  }
  editDay = (timestamp,keys) => {
    const { setUnsavedVal,editdaylist }=this.props;
   
    if(Object.keys(editdaylist).length<=0){
        this.setState({
                typeEdit: true
            });
      setUnsavedVal('push',timestamp+keys)
    }else{
      setUnsavedVal('push',timestamp+keys,true);
    }
}
  setNotification(changes, thetype) {
      const {user} = this.props;
      this.props.socket.emit('profile', {type: 'getForDashboard', data: {
            'subtype': 'setNotification', 
            'userId': user.user_id,
            'changes': changes,
            'thetype': thetype,
          } 
      });
  }

  componentDidMount() {
    if (this.MainDayModRef) {
      const nextActive = moment().utc().startOf('day').add(72, 'h');
      if (this.props.date && this.props.date.isSame(nextActive)) {
        this.MainDayModRef.parentElement.scrollTop = this.MainDayModRef.offsetTop;
      }
      setTimeout(() => this.setState({ xyz: true }), 3 * animateTime);
    }
  }

  componentWillReceiveProps(next) {
    if (this.MainDayModRef && !this.state.activeType && next.planParams && next.planParams.mealType) {
      this.setState({ activeType: next.planParams.mealType.reduce((acc, cur) => +cur < +acc ? +cur : +acc, Infinity) });
    }
  }

  renderTypeList = (buffered,bagName) => {
    const { activeType, typeEdit,ratings } = this.state;
    const { dataArray, planParams, placeOrder, date, pause, pause_start, pause_end, custom,customPlaceOrder, setUnsavedVal,mealPlan } = this.props;
    const { typeList } = dataArray;

    var paused = false;
    let unix = date.unix();
    if (pause) {
      if (unix >= pause_start && !pause_end) {
        paused = true;
      }

      if (unix >= pause_start && unix <= pause_end) {
        paused = true;
      }
    }

    const types = Object.keys(mealPlan[unix]) || (planParams['mealType'] || []);
    const list = types.sort((a, b) => typeList[a].order - typeList[b].order).map(typeId => {
      const type = typeList && typeList[typeId];
      return (
        <li
          key={typeId}
          className={+typeId === +activeType ? 'active' : ''}
          onClick={() => this.setState({ activeType: +typeId })}
        >
          {type.title}
        </li>
      );
    });
    if (typeEdit && !buffered && !paused) {
      return (
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            {list}
            <li
              className='bg-gold'
              onClick={() => {
                this.setState({ typeEdit: false });
                //this.caloriesCalculation();
                setUnsavedVal('pop',unix+bagName);
                {!custom && placeOrder('no',unix,activeType) }
                {custom && customPlaceOrder()}
                this.setNotification('mealplan menu edit', 1);
              }}
            >
                        <span>Save Changes</span>
            </li>
          </ul>
          <p className='click-on'>Click on the "Item card" to make selection.</p>
        </Fragment>
      );
    } else if (!typeEdit && !buffered && !paused) { 
      return (
        <ul className='dish-groups pl-0 mb-0'>
          <li
            onClick={() => this.editDay(unix,bagName)}
          >
                    Change items</li>
          <li className='can-change'>You can change the selection on your own</li>
        </ul>
      );
    } else if (buffered && !paused) {
      return ( 
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border'>You cannot edit or skip this day. Items are delivered or in the process of creation.</li>
          </ul>
        </Fragment>
      );
    } else if (paused) {
      return ( 
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border'>You cannot edit or skip this day. The day is on pause.</li>
          </ul>
        </Fragment>
      );
    } else if (custom) {
      return ( 
        <Fragment>
          <ul className='dish-groups pl-0 mb-0'>
            <li className='can-change no-border delivery-bage'>Please contact our team to change dishes or date for the additional delivery</li>
          </ul>
        </Fragment>
      );
    }
  };

  sidesModal = (date, dish_id) => {
    const {setModal, sidesList, usersides, planParams, oilList} = this.props;
    var {sides, basicside, oilside} = this.state;
    /*if (!sides && usersides) {
      sides = [];
      if (typeof(usersides[date]) != 'undefined') {
        if (typeof(usersides[date][dish_id]) != 'undefined') {
          Object.keys(usersides[date][dish_id]).map( (el, idx) => {
            sides.push(usersides[date][dish_id][el].side_id);
          });
        }
      }
    }*/

    setModal({ headLine: 'Edit Sides', message: 
      (
        <div>
                  {sidesList && <Select2 
                    placeholder='Side'
                    data={sidesList} 
                    multiple={false}
                    value={basicside || ''}
                    update={ 
                      (value) => this.sidesChange(value, date, dish_id, 'basicside')
                    }>
                  </Select2>}

                  {(planParams.diet == 4) && oilList && <Select2 
                    placeholder='Oil Side'
                    data={oilList} 
                    multiple={false}
                    value={oilside || ''}
                    update={ 
                      (value) => this.sidesChange(value, date, dish_id, 'oilside')
                    }>
                  </Select2>}

                  <p align="center">
                  <PaperButton onClick={() => this.saveSides(sides, date, dish_id)} className='button-small transition next'>
                    <span>Save</span>
                  </PaperButton>
                  </p>
        </div>
      )
    }); 
  }

  addToCart(dish_id, to_dish_id, type_id, date) { 
    const {planParams} = this.props;
    this.props.socket.emit('order', { type: 'addToCart', data: {userId: this.props.user.user_id, dishId: dish_id, toDishId: to_dish_id, typeId: type_id, date: date, orderId: planParams.id } });
    const {clearModal} = this.props;
    clearModal();
  }

  buySidesModal = (date, dish_id, type_id) => {
    const {setModal, planParams, buySidesList} = this.props;
    var {buyside} = this.state;
    setModal({ headLine: 'Buy Sides', message: 
      (
        <div>
                  {buySidesList && <Select2 
                    placeholder='Side'
                    data={buySidesList} 
                    multiple={false}
                    value={buyside || ''}
                    update={ 
                      (value) => this.sidesChange(value, date, dish_id, 'buyside', type_id)
                    }>
                  </Select2>}

                  <p align="center">
                  <PaperButton onClick={() => this.addToCart(buyside, dish_id, type_id, date)} className='button-small transition next'>
                    <span>Add To Cart</span>
                  </PaperButton>
                  </p>
        </div>
      )
    }); 
  }
  confirmSkipModal = (date) => {
    const {setModal,skipTheDay,clearModal} = this.props;
   
     setModal({ headLine: 'Are you sure?', message: 
       (
         <div>
           <ul className="list">
             <li>This action cannot be reversed.</li>
             <li>A day will be added to the end of your plan.</li>
             <li>Default dishes will be automatically selected unless you change the dishes according to your preferences.</li>
             <li>Please inform us by message in case you want us to check the menu</li>
           </ul>
             <p align="center">
                   <PaperButton onClick={() =>{skipTheDay(date);clearModal();}} className='button-small transition next'>
                     <span>Yes</span>
                   </PaperButton>
                   </p>
            <p align="center">
                   <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                     <span>No</span>
                   </PaperButton>
                   </p>      
         </div> 
       )
     }); 
   }
  sameDishIsSelected = (mealtype, dishid, bagname='') => {
    const { mealPlan, goMenu, date, setCustomMealPlan, setCustomPlanParams, planParams, dataArray, customPlans, ratings, dietproducts, custom, customdays, usersides, setModal, customMealPlan } = this.props;
    let unix = date.unix(); // so in the products card we will have the time stamp
    let dishExist = '';
    let alertmsg = 'Please note, you have selected the same dish on the other bag!';
    var mealTypeArr = {1:'Breakfast',2:'Morning Snack',3:'Lunch',4:'Evening Snack',5:'Dinner'}
    if(bagname != ''){
      Object.keys(mealPlan[unix]).map((type) => {
        if(type == mealtype && dishid == mealPlan[unix][type]){
          dishExist = 2;
          return '';
        }
      });
      if(customMealPlan.hasOwnProperty(unix)){
      Object.keys(customMealPlan[unix]).map((bag)=>{
        Object.keys(customMealPlan[unix][bag]).map((type)=>{
          if(type == mealtype && dishid == customMealPlan[unix][bag][type]){
            dishExist = 2;
            return '';
          } 
        })
      });
      // SAME PANEL CHECK FOR ADDITIONAL BAG
      Object.keys(customMealPlan[unix][bagname]).map((val)=>{
        if(dishid == customMealPlan[unix][bagname][val]){
          dishExist = 2;
          alertmsg = "Please note you have selected same dish for "+ mealTypeArr[val] +". Do you want to select same Dish? "
          return '';
        }
      });
    }
    }else{
       if(customMealPlan.hasOwnProperty(unix)){
      Object.keys(customMealPlan[unix]).map((bag)=>{
        Object.keys(customMealPlan[unix][bag]).map((type)=>{
          if(type == mealtype && dishid == customMealPlan[unix][bag][type]){
            dishExist = 2;
            return '';
          } 
        })
      });
      // SAME PANEL
      Object.keys(mealPlan[unix]).map((type) => {
        if(dishid == mealPlan[unix][type]){
          dishExist = 2;
          alertmsg = "Please note you have selected same dish for "+ mealTypeArr[type] +". Do you want to select same Dish? "
          return '';
        }
      });
    }
    }
    return {'dishExist':dishExist,'msg':alertmsg};
  }
  toggleLike=(productId,type,type_id)=>{
    this.props.socket.emit('profile', { type: 'setDishlike', data: {userId:this.props.user.user_id, dishId: productId,action:type,meal_type:type_id}});
  }
  showDishDetails= (productId) =>{
    const { setModal , dataArray} = this.props;
    const { products } =dataArray;
   const { ratings }=this.state;
   setModal({ headLine: '', message:
     (<DishDescription
                         product={{ ...products[productId], id: productId }}
                         ingredients={dataArray['ingredientList'] || {}}
                         ratings={ratings}
                         onClick={() => {
                           // this.hide('showDescription');
                           // this.hideToggler(true);
                         }}
                       /> )
                     });
 }
  rateDishModal=(productId,type_id)=>{
    const { setModal} = this.props;
    const {ratings}=this.state;
    var rate=ratings[productId]?ratings[productId]:0;
    setModal({ headLine: 'Your feedback is valuable', message:
      (
        <RatingModal
           productId={productId}
           rating={rate}
           saveRating={(product_id, rating,feedback=null)=>{
            const {clearModal} = this.props;
            this.props.socket.emit('profile', {type: 'getForDashboard', data: {
                  'subtype': 'setDishRatingNew',
                  'userId': this.props.user.user_id,
                  'productId': product_id,
                  'rating': rating,
                  'mealtype': type_id,
                  'feedback':feedback
                }
            });
            if(ratings[product_id]!=undefined){
                ratings[product_id]=rating;
                let ratings1=ratings;
                this.setState({
                  ratings:ratings1
                })
              }
              clearModal();
            }
            }
        />
      )
    });
  }
  renderCarousel =(breakpoints,slidesPerPage=4,slides)=>{
      return <Carousel
      arrowLeft={<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>}
      arrowRight={<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>}
      addArrowClickHandler
      onChange={this.onSliderChange}
      slidesPerPage={slidesPerPage}
      slidesPerScroll={1}
      slides={slides}
      breakpoints={breakpoints}
      />;
  }
  roundNumber = (num)=>{
    return Math.round(num * 100) / 100;
  }
  renderCustomProduct = (bagName,buffered) => {
    const { activeType, typeEdit, showinfo } = this.state;
    const { goMenu, date, setCustomMealPlan, setCustomPlanParams, planParams, dataArray, customPlans, ratings, dietproducts, custom, customdays, usersides, setModal, user,diet_id, customMealPlan } = this.props;
    const { preferences, allergies, dislikes,dishdislikes,eliminate_groups,dishlikes } = user;   
    const { typeList, dishLabels,ingredientList } = dataArray;
    const {clearModal}=this.props;
    const { sideDishdate } = planParams;
    let unix = date.unix(); // so in the products card we will have the time stamp
    var mealPlan = {};
     mealPlan[unix] = customMealPlan[unix][bagName]; 
    if (!dietproducts) {
      var {products} = dataArray;
  } else {
      var products = dietproducts;
  }
  const dayNum = date.date() - 1;

  const customonChange = (value, checked,is_confirm_dislike=false) => {
    var mealDishAlone = Object.values(customMealPlan[unix])[0];
    let oldDatePlan = { ...customMealPlan[unix] };
    var {dislikeproducts,allergyproducts} = this.state;
    const modifyOldPlan = function(spy){
      Object.keys(spy).forEach(function(key,ind,arr){
          if(key == bagName){
          spy[key][activeType] = +value;
          }
      });
    return spy;
    };
    var swalselectVal = 0;
    var vals = {};
    // console.log("======= DEBUG STATEMENT =================");
    // console.table(oldDatePlan);
    // var valt = { ...customdays, [unix]: {...{[bagName]:{...Object.values(customdays[unix])[0],[activeType]: +value}}}};
    // vals = {...Object.values(customdays[unix])[0]};
    // console.table(valt);
    // console.log(value);
    // console.log(unix);
    // console.log("======= DEBUG STATEMENT =================");
    if(allergyproducts.indexOf(value) != -1&&!is_confirm_dislike){
      setModal({ headLine: 'Ingredient as a allergy', message: (
        <div>
        <p>Sorry, due to your allergy preference this dish is not available for selection.</p>
        </div>)
      });
    }else if(dislikeproducts.indexOf(value) != -1&&!is_confirm_dislike){
      /*setModal({ headLine: 'Ingredient as a Dislike', message: (
        <div>
        <p>This dish has an ingredient which is in your dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.
        If not, we would recommend you to select another dish</p>
        <p align="center">
        <PaperButton onClick={() =>{customonChange(value,checked,true);clearModal();}} className='button-small transition next'>
        <span>SELECT THIS DISH</span>
        </PaperButton>
        </p>
        <p align="center">
        <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
        <span>DO NOT SELECT</span>
        </PaperButton>
        </p> 
        </div>)
      });*/
      var disLikemsg = "This dish has an ingredient which is in your dislike list. \n\nPlease note we would not be able to remove the ingredient, if you choose to opt for this dish.\n\nIf not, we would recommend you to select another dish"
      if(window.confirm(disLikemsg)){
        if (checked) {
          const {dishExist,msg} = this.sameDishIsSelected(activeType, value,bagName);
        if(dishExist == 2){
          if(window.confirm(msg)){
            if (checked) {
              setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
            } else {
              //delete oldDatePlan[activeType];
              setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
            }
          }else{
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          }
        }else{
          if (checked) {
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          }
        }
        } else {
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
        }
      }else{
        setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
      }
    }else if(!is_confirm_dislike){
      const {dishExist,msg} = this.sameDishIsSelected(activeType, value,bagName);
      if(dishExist == 2){
        if(window.confirm(msg)){
          if (checked) {
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          }
        }else{
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
        }
      }else{
        if (checked) {
          setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
        }else{
          //delete oldDatePlan[activeType];
          setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
        }
      }
    }

      if(is_confirm_dislike){
        const {dishExist,msg} = this.sameDishIsSelected(activeType, value,bagName);
        if(dishExist == 2){
          if(window.confirm(msg)){
            if (checked) {
              setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
            } else {
              //delete oldDatePlan[activeType];
              setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
            }
          }else{
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          }
        }else{
          if (checked) {
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          } else {
            //delete oldDatePlan[activeType];
            setCustomMealPlan({ ...customMealPlan, [unix]: { ...oldDatePlan[unix], [bagName]: {...oldDatePlan[bagName],[activeType] : +value } }});
          }
        }
      }
      
    setCustomPlanParams({ ...planParams, modified: true });
    
  };
  var modifyMealPlan = Object.values(customMealPlan[unix])[0];
  
  const plan = customPlans.find(el => +el.mealType_id === +activeType &&  +el.diet_id === +diet_id) || {};

  let prodKeys = (plan.data || {})[dayNum] || [];
  // if (allergies && dislikes && allergies.length && dislikes.length) {
  //   prodKeys = prodKeys.filter(el => intersect(products[el].ingredients, allergies, dislikes).length === 0);
  // }
  if (eliminate_groups && eliminate_groups.length ) {
      // prodKeys = prodKeys.filter(el => !eliminate_groups.includes(products[el].group));
      prodKeys = prodKeys.filter(function (dishId) {
        const { group,spicy } = products[dishId] || {};
        let isEliminate = true;
        if(eliminate_groups.length>0){ 
          // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
          isEliminate = !(intersect(group,eliminate_groups).length); 
          if(isEliminate){
             isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
          }
        }
        return isEliminate;
    });
  }
  if (dishdislikes && dishdislikes.length ) {
      prodKeys = prodKeys.filter(el => !dishdislikes.includes(el));
  }
  
  if (preferences && preferences.length) {
    prodKeys = prodKeys.sort((el1, el2) => intersect(products[el2].ingredients, preferences) - intersect(products[el1].ingredients, preferences));
  }
  const list = typeEdit ? prodKeys : Object.keys(mealPlan[unix] || {}).sort((el1, el2) => typeList[el1].order - typeList[el2].order);
  return list.map((key, idx) => {

    const productId = typeEdit ? key : mealPlan[unix][key];
    const type_id = typeEdit ? activeType : key;
    const product = (products || {})[productId];
    const checked = typeEdit && mealPlan[unix] && (mealPlan[unix][activeType] === +productId);
  
    const title = typeEdit ? typeList[activeType].title + ' option #' + (idx + 1) : typeList[key].title;
    var label = false;
    if (dishLabels && typeof(dishLabels[product['label_id']]) != 'undefined') {
      label = dishLabels[product['label_id']];
    }
          /* Start Added the dislike,allergies */
      var allergens = null;
      var dontlike = null;
      var ing = [];
      var dont = [];
      if (product.hasAllergens) {
          allergens = 'Allergies:'
          product.allergens.map( (allergen_id, index) => {
            var hasuser = null; 
            if (allergies && allergies.indexOf(allergen_id) != -1) {
              this.updateNotselectableProducts(productId,'allergy');		
              ing.push(ingredientList[allergen_id]);
            }
            
          });
        }
        
        product.ingredients.map( (ingredient_id, index) => {
          if (dislikes && dislikes.indexOf(ingredient_id) != -1) {
            dontlike = 'Dislikes:'
            dont.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'dislike');
          }else if(allergies && allergies.indexOf(ingredient_id) != -1){
            ing.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'allergy');
          }
        });
     /* End Added the dislike,allergis */

    
    var fat = product['fat'] / 1000;
    var protein = product['protein'] / 1000;
    var carb = product['carb'] / 1000;
    var calories = product['calories'] / 1000;
    var thetitle = product.title;
    var editsides = false; 
    var alternative = false;
    var dietside = false;
    var buysides = false;
    var longblock = false;
    if (product['dishType'] == 'composite') {
      buysides = true;
    }
    var prefix = ' with ';
    if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
      if (typeof(usersides[unix][productId]) != 'undefined') {
          var theproduct = products[productId];
          fat = theproduct.fat / 1000;
          protein = theproduct.protein / 1000;
          carb = theproduct.carb / 1000;
          calories = theproduct.calories / 1000;
          thetitle = theproduct.title;
         
          Object.keys(usersides[unix][productId]).map( (el, idx) => {
              var side = usersides[unix][productId][el];         
              if (typeof(products[side.side_id]) != 'undefined') {
                editsides = true;
                
                if (!dietside && planParams.diet == 4 && side.category == 2) { 
                  // for keto and oils:
                  fat += products[side.side_id].fat / 1000;
                  protein += products[side.side_id].protein / 1000;
                  carb += products[side.side_id].carb / 1000;
                  calories += products[side.side_id].calories / 1000;
                  thetitle = thetitle + prefix + products[side.side_id].title;
                  prefix = ' & ';
                  dietside = true;
                }

                if (!alternative || side.type != 'alternative') {
                  if (!(dietside && side.category == 2)) {
                    fat += products[side.side_id].fat / 1000;
                    protein += products[side.side_id].protein / 1000;
                    carb += products[side.side_id].carb / 1000;
                    calories += products[side.side_id].calories / 1000;
                    thetitle = thetitle + prefix + products[side.side_id].title;
                    prefix = ' & ';
                    if (side.type == 'alternative') {
                      alternative = true;
                    }
                  }
                }
              }
          });
      }
    }

    if (!alternative) {
      Object.keys(product['all_sides']).map( (el, idx) => {
        var side_id = product['all_sides'][el];
        if (typeof(products[side_id]) != 'undefined') {
            fat += products[side_id].fat / 1000;
            protein += products[side_id].protein / 1000;
            carb += products[side_id].carb / 1000;
            calories += products[side_id].calories / 1000;
            thetitle = thetitle + prefix + products[side_id].title;
            prefix = ' & ';
            editsides = true;
            longblock = true;
        }
      });
    }
    
    var showit = false;

    Object.keys(showinfo).map( (el, idx) => {
      if (showinfo[el] == title) {
        showit = true;
      }
    });

    if (longblock && !showit) {
      thetitle = product.title + ' with sides';
    }
    let emoji=CardEmojiIcon;
      let emojiClass='norate';
      if(ratings[productId]!=undefined){
        switch(ratings[productId]){
            case 5:
              emoji=LoveEmojiIcon;
              emojiClass='rateok';
            break;
            case 4:
              emoji=GoodEmojiIcon;
              emojiClass='rateok';
            break;
            case 3:
              emoji=OkEmojiIcon;
              emojiClass='rateok';
            break;
            case 2:
              emoji=BadEmojiIcon;
              emojiClass='rateok';
            break;
        }
      }
    return product ? (
      <div className='products' key={idx} onWheel={e => e.stopPropagation()}>
        <div className={`product transition d-card-inr-holder ${checked ? 'chosen' : 'not'} ${(ing.length>0)?'light-fade':''}`}>
        <span class="selected_dish"><i class="fa fa-check-circle" aria-hidden="true"></i>Selected</span>
        <div className='img-holder d-card-img'>
            {/*  onClick={() =>{ !typeEdit && goMenu('showDescription', +productId)}} */}
            <div className={`image_click ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && customonChange(productId, !checked); }}>
              <img src={product.preview || noImage} />
            </div>    
            <div class="prod-rat-ics">
              <button className={`d-like-btn ${Array.isArray(dishlikes)&&dishlikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'like',type_id)}> <i></i><span>liked!</span></button>
              <button className={`d-emoji-display ${Array.isArray(dishdislikes)&&dishdislikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'dislike',type_id)}><i></i></button>
    <a onClick={()=>{this.rateDishModal(productId,type_id)}} className="rate-emoji"><img className={`${emojiClass}`} src={`${emoji}`} /></a>
            </div>
            <h3 className={` ${typeEdit && checked ? 'gold' : ''}`}>{checked ? 'Selected option' : title}</h3>
            {typeEdit && <div className="edit-label" onClick={() => { typeEdit && customonChange(productId, !checked); }}>
              <h2 className='product-title transition'>{thetitle}</h2>
              {label && <span className="dish-label-value l-green-label">{label}</span>}
              </div>}
        </div>

        <div className={`inner d-card-caption ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && customonChange(productId, !checked,false); }}>
            <h2 onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='product-title transition'>{thetitle}</h2>
            {!typeEdit && label && <span className="dish-label-value l-green-label">{label}</span>}
            {editsides && <span
                onClick={e => {
                    this.sidesModal(unix, productId);
                }}
                className='editsides-btn'>
              Edit Sides</span>
            }
            {buysides && <span onClick={ e => {
              var thetype = null;
              Object.keys(mealPlan[unix]).map( (el, idx) => {
                if (mealPlan[unix][el] == productId) {
                  thetype = el;
                }
              });
              this.buySidesModal(unix, productId, thetype);
            }} className='editsides-btn'>Buy Sides</span>}
            { (!longblock || showit) && <div>

<div className='main-dish'>{ typeEdit && <p class="sbold">Main Dish</p>}<div className="d-kal-info mb-3">
<span onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${calories}`}</span>
<span>
  {`PRO ${protein}g`}
</span>
<span>
  {`CHO ${carb}g`}
</span>
<span>
  {`F ${fat}g`}
</span>
</div></div>

{ing.length>0&&<div className="dc-alergy-holder d-flex">
      <p className='red'> Allergy : <span>{(ing.length>1)?ing.join(','):ing}</span></p>
    </div>}
{dont.length>0&&<div className="dc-dislike-holder d-flex">
<p className='orange'> Dislike : <span>{(dont.length>1)?dont.join(','):dont}</span></p>
</div>}        
<span className='spicy'>{product['spicy'] ? 'Spicy' : ''}</span>      


</div>}
          {longblock && !showit && <span className="show-info" onClick={() => {
            showinfo.push(title);
            this.setState({
              showinfo: showinfo
            })
          }}>Show Full Information</span>}
          </div>
          { (!longblock || showit) &&<div className="d-flex discrd-ftr">
<div className="discrd-ftr-lt">
{/* <button className='btn-label-turq-outline d-v-more-btn' onClick={() => goMenu('showDescription', +productId)}>View details</button> */}
<button className='btn-label-turq-outline d-v-more-btn' onClick={() => this.showDishDetails(+productId)}>View details</button>
</div>
</div>}
        </div> 
      </div>
    ) : null;
  });
    
   }
  renderProduct = () => {
    const { activeType, typeEdit, showinfo } = this.state;
    const { goMenu, date, setMealPlan, setPlanParams, planParams, dataArray, customPlans, ratings, dietproducts, custom, customdays, usersides, setModal, user,diet_id} = this.props;
    const { preferences, allergies, dislikes,dishdislikes,eliminate_groups,dishlikes } = user;   
    const { typeList, dishLabels,ingredientList } = dataArray;
    const {clearModal}=this.props;
    var {mealPlan} = this.props; 

    let unix = date.unix(); // so in the products card we will have the time stamp
    // console.log(unix);
    if (!dietproducts) {
        var {products} = dataArray;
    } else {
        var products = dietproducts;
    }

    const dayNum = date.date() - 1;
    const onChange = (value, checked, is_confirm_dislike) => {
      let oldDatePlan = { ...mealPlan[unix] };
      var {dislikeproducts,allergyproducts} = this.state;
      
      if(allergyproducts.indexOf(value) != -1&&!is_confirm_dislike){
        setModal({ headLine: 'Ingredient as a allergy', message: (
          <div>
            <p>Sorry, due to your allergy preference this dish is not available for selection.</p>
          </div>)
        });
     }else if(dislikeproducts.indexOf(value) != -1 && !is_confirm_dislike){
      setModal({ headLine: 'Ingredient as a Dislike', message: (
        <div>
          <p>This dish has an ingredient which is in your dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.
If not, we would recommend you to select another dish</p>
      <p align="center">
                <PaperButton onClick={() =>{onChange(value,checked,true);clearModal();}} className='button-small transition next'>
                  <span>SELECT THIS DISH</span>
                </PaperButton>
                </p>
         <p align="center">
                <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                  <span>DO NOT SELECT</span>
                </PaperButton>
                </p> 
        </div>)
      });
    }else if(!is_confirm_dislike){
      const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
      if(dishExist == 2){
        if(window.confirm(msg)){
          if (checked) {
            setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
          } else {
            delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }else{
          //delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
        }
      }else{
        if (checked) {
          //this.sameDishIsSelected(activeType, value);
          setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
        } else {
          delete oldDatePlan[activeType];
          setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
        }
      }
    }

      if(is_confirm_dislike){
        const {dishExist,msg} = this.sameDishIsSelected(activeType, value);
        if(dishExist == 2){
          if(window.confirm(msg)){
            if (checked) {
              //this.sameDishIsSelected(activeType, value);
              setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
            } else {
              delete oldDatePlan[activeType];
              setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
            }
          }else{
            //delete oldDatePlan[activeType];
              setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }else{
          if (checked) {
            //this.sameDishIsSelected(activeType, value);
            setMealPlan({ ...mealPlan, [unix]: { ...oldDatePlan, [activeType]: +value } });
          } else {
            delete oldDatePlan[activeType];
            setMealPlan({ ...mealPlan, [unix]: oldDatePlan });
          }
        }
      }
      
       setPlanParams({ ...planParams, modified: true });
    };
    const plan = customPlans.find(el => +el.mealType_id === +activeType &&  +el.diet_id === +diet_id) || {};

    let prodKeys = (plan.data || {})[dayNum] || [];
    // if (allergies && dislikes && allergies.length && dislikes.length) {
      //prodKeys = prodKeys.filter(el => intersect(products[el].ingredients, allergies, dislikes).length === 0);
    // }
    if (eliminate_groups && eliminate_groups.length ) {
        // prodKeys = prodKeys.filter(el => !eliminate_groups.includes(products[el].group));
        prodKeys = prodKeys.filter(function (dishId) {
          const { group,spicy } = products[dishId] || {};
          let isEliminate = true;
          if(eliminate_groups.length>0){ 
            // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
            isEliminate = !(intersect(group,eliminate_groups).length); 
            if(isEliminate){
               isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
            }
          }
          return isEliminate;
      });
    }
    if (dishdislikes && dishdislikes.length ) {
        prodKeys = prodKeys.filter(el => !dishdislikes.includes(el));
    }
    if (preferences && preferences.length) {
      prodKeys = prodKeys.sort((el1, el2) => intersect(products[el2].ingredients, preferences) - intersect(products[el1].ingredients, preferences));
    }
    const list = typeEdit ? prodKeys : Object.keys(mealPlan[unix] || {}).sort((el1, el2) => typeList[el1].order - typeList[el2].order);

    return list.map((key, idx) => {
      const productId = typeEdit ? key : mealPlan[unix][key];
     const type_id = typeEdit ? activeType : key;
    const product = (products || {})[productId];
      const checked = typeEdit && mealPlan[unix] && (mealPlan[unix][activeType] === +productId);
      const title = typeEdit ? typeList[activeType].title + ' option #' + (idx + 1) : typeList[key].title;
      var label = false;
      if (dishLabels && typeof(dishLabels[product['label_id']]) != 'undefined') {
        label = dishLabels[product['label_id']];
      }

      /* Start Added the dislike,allergies */
      var allergens = null;
      var dontlike = null;
      var ing = [];
      var dont = [];
      if (product.hasAllergens) {
          allergens = 'Allergies:'
          product.allergens.map( (allergen_id, index) => {
            var hasuser = null; 
            if (allergies && allergies.indexOf(allergen_id) != -1) {
              this.updateNotselectableProducts(productId,'allergy');		
              ing.push(ingredientList[allergen_id]);
            }
            
          });
        }
        
        product.ingredients.map( (ingredient_id, index) => {
          if (dislikes && dislikes.indexOf(ingredient_id) != -1) {
            dontlike = 'Dislikes:'
            dont.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'dislike');
          }else if(allergies && allergies.indexOf(ingredient_id) != -1){
            ing.push(ingredientList[ingredient_id]);
            this.updateNotselectableProducts(productId,'allergy');
          }
        });
     /* End Added the dislike,allergis */

      var fat = product['fat'] / 1000;
      var protein = product['protein'] / 1000;
      var carb = product['carb'] / 1000;
      var calories = product['calories'] / 1000;
      var thetitle = product.title;
      var editsides = false; 
      var alternative = false;
      var dietside = false;
      var buysides = false;
      var longblock = false;
      if (product['dishType'] == 'composite') {
        buysides = true;
      }
      var prefix = ' with ';
      if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
        if (typeof(usersides[unix][productId]) != 'undefined') {
            var theproduct = products[productId];
            fat = theproduct.fat / 1000;
            protein = theproduct.protein / 1000;
            carb = theproduct.carb / 1000;
            calories = theproduct.calories / 1000;
            thetitle = theproduct.title;
           
            Object.keys(usersides[unix][productId]).map( (el, idx) => {
                var side = usersides[unix][productId][el];         
                if (typeof(products[side.side_id]) != 'undefined') {
                  editsides = true;
                  
                  if (!dietside && planParams.diet == 4 && side.category == 2) { 
                    // for keto and oils:
                    fat += products[side.side_id].fat / 1000;
                    protein += products[side.side_id].protein / 1000;
                    carb += products[side.side_id].carb / 1000;
                    calories += products[side.side_id].calories / 1000;
                    thetitle = thetitle + prefix + products[side.side_id].title;
                    prefix = ' & ';
                    dietside = true;
                  }

                  if (!alternative || side.type != 'alternative') {
                    if (!(dietside && side.category == 2)) {
                      fat += products[side.side_id].fat / 1000;
                      protein += products[side.side_id].protein / 1000;
                      carb += products[side.side_id].carb / 1000;
                      calories += products[side.side_id].calories / 1000;
                      thetitle = thetitle + prefix + products[side.side_id].title;
                      prefix = ' & ';
                      if (side.type == 'alternative') {
                        alternative = true;
                      }
                    }
                  }
                }
            });
        }
      }

      if (!alternative) {
        Object.keys(product['all_sides']).map( (el, idx) => {
          var side_id = product['all_sides'][el];
          if (typeof(products[side_id]) != 'undefined') {
              fat += products[side_id].fat / 1000;
              protein += products[side_id].protein / 1000;
              carb += products[side_id].carb / 1000;
              calories += products[side_id].calories / 1000;
              thetitle = thetitle + prefix + products[side_id].title;
              prefix = ' & ';
              editsides = true;
              longblock = true;
          }
        });
      }
      
      var showit = false;

      Object.keys(showinfo).map( (el, idx) => {
        if (showinfo[el] == title) {
          showit = true;
        }
      });

      if (longblock && !showit) {
        thetitle = product.title + ' with sides';
      }
      let emoji=CardEmojiIcon;
      let emojiClass='norate';
      if(ratings[productId]!=undefined){
        switch(ratings[productId]){
            case 5:
              emoji=LoveEmojiIcon;
              emojiClass='rateok';
            break;
            case 4:
              emoji=GoodEmojiIcon;
              emojiClass='rateok';
            break;
            case 3:
              emoji=OkEmojiIcon;
              emojiClass='rateok';
            break;
            case 2:
              emoji=BadEmojiIcon;
              emojiClass='rateok';
            break;
        }
      }
      return product ? (
        <div className='products' key={idx} onWheel={e => e.stopPropagation()}>
          <div className={`product transition d-card-inr-holder ${checked ? 'chosen' : 'not'} ${(ing.length>0)?'light-fade':''}`}>
          <span class="selected_dish"><i class="fa fa-check-circle" aria-hidden="true"></i>Selected</span>
          <div className='img-holder d-card-img'>
            {/*  onClick={() =>{ !typeEdit && goMenu('showDescription', +productId)}} */}
            <div className={`image_click ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && onChange(productId, !checked); }}>
              <img src={product.preview || noImage} />
            </div>    
            <div class="prod-rat-ics">
              <button className={`d-like-btn ${Array.isArray(dishlikes)&&dishlikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'like',type_id)}> <i></i><span>liked!</span></button>
              <button className={`d-emoji-display ${Array.isArray(dishdislikes)&&dishdislikes.indexOf(productId)>-1 ? 'active' : ''}`} onClick={() =>this.toggleLike(productId,'dislike',type_id)}><i></i></button>
    <a onClick={()=>{this.rateDishModal(productId,type_id)}} className="rate-emoji"><img className={`${emojiClass}`} src={`${emoji}`} /></a>
            </div>
            <h3 className={` ${typeEdit && checked ? 'gold' : ''}`}>{checked ? 'Selected option' : title}</h3>
            {typeEdit && <div className="edit-label" onClick={() => { typeEdit && onChange(productId, !checked); }}>
              <h2 className='product-title transition'>{thetitle}</h2>
              {label && <span className="dish-label-value l-green-label">{label}</span>}
              </div>}
        </div>

            <div className={`inner d-card-caption ${typeEdit ? 'clickable' : ''}`} onClick={() => { typeEdit && onChange(productId, !checked,false); }}>
             
              {!typeEdit && <h2 onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='product-title transition'>{thetitle}</h2>}
              {!typeEdit && label && <span className="dish-label-value l-green-label">{label}</span>}
              {editsides && <span
                  onClick={e => {
                      this.sidesModal(unix, productId);
                  }}
                  className='editsides-btn'>
                Edit Sides</span>
              }
              {buysides && <span onClick={ e => {
                var thetype = null;
                Object.keys(mealPlan[unix]).map( (el, idx) => {
                  if (mealPlan[unix][el] == productId) {
                    thetype = el;
                  }
                });
                console.log(thetype);
                this.buySidesModal(unix, productId, thetype);
              }} className='editsides-btn'>Buy Sides</span>}
              { (!longblock || showit) && <div>
                <div className='main-dish'><div className="d-kal-info mb-3">
              <span onClick={() => { !typeEdit && goMenu('showDescription', +productId)}} className='card-kacal'><i><img src={caloriesIcon} /></i>{`Kcal ${calories}`}</span>
              <span>
                  {`PRO ${protein}g`}
                </span>
              <span>
                  {`CHO ${carb}g`}
                </span>
                <span>
                  {`F ${fat}g`}
                </span>
              </div></div>
              {ing.length>0&&<div className="dc-alergy-holder d-flex">
                      <p className='red'> Allergy : <span>{(ing.length>1)?ing.join(','):ing}</span></p>
                    </div>}
              {dont.length>0&&<div className="dc-dislike-holder d-flex">
                <p className='orange'> Dislike : <span>{(dont.length>1)?dont.join(','):dont}</span></p>
              </div>}        
              <span className='spicy'>{product['spicy'] ? 'Spicy' : ''}</span>      
              
            
            </div>}
            {longblock && !showit && <span className="show-info" onClick={() => {
              showinfo.push(title);
              console.log(showinfo);
              this.setState({
                showinfo: showinfo
              })
            }}>Show Full Information</span>}
            </div>
            { (!longblock || showit) && <div className="d-flex discrd-ftr">
                <div className="discrd-ftr-lt">
                {/* <button className='btn-label-turq-outline d-v-more-btn' onClick={() => goMenu('showDescription', +productId)}>View details</button> */}
                <button className='btn-label-turq-outline d-v-more-btn' onClick={() => this.showDishDetails(+productId)}>View details</button>
                </div>
             </div>}
          </div> 
        </div>
      ) : null;
    });
  };

  render() {
    const { date, inactive, skipped, changeSkipped, skipTheDay, originTo, custom, dietproducts, customdays, usersides, dataArray, pause, pause_start, pause_end, pauseList, customMealPlan} = this.props;
    const { xyz,typeEdit,activeType } = this.state; //calories, fat, protein, cho
    const unix = date.unix();
    const isSkipped = unix in skipped;
    const today = moment().startOf('day');
    const buffered = date.isBetween(today, today.clone().add(3, 'day'), '[)');
    const isdayactive=(buffered||inactive);
    var {mealPlan} = this.props;
    const {products} = dataArray;
    let paused = false;
    // if (pause) {
    //   if (unix >= pause_start && !pause_end) {
    //     paused = true;
    //   }

    //   if (unix >= pause_start && unix <= pause_end) {
    //     paused = true;
    //   }
    // }
    let activepause = false;
    if(pauseList!=undefined && pauseList[unix]!=undefined){
        activepause=pauseList[unix];
    }
    if (custom) {
        mealPlan = customMealPlan;
    }
      var sum_calories = 0;
      var sum_protein = 0;
      var sum_carbs = 0;
      var sum_fat = 0;
      
      var breakpoints={
        1400: {
          slidesPerPage: 4,
          slidesPerScroll:1
        },
        992: { // these props will be applied when screen width is less than 1000px
          slidesPerPage: (!typeEdit) ? 3 : 2,
          slidesPerScroll:1,
        },
        479: {
          slidesPerPage: 1,
          slidesPerScroll:1,
        },
    };
      if (typeof(mealPlan[unix]) != 'undefined') {
         if(!custom){
            Object.keys(mealPlan[unix]).map( (el, idx) => {
              var product_id = mealPlan[unix][el];
              var calories = dietproducts[product_id].calories/1000;
              var carb = dietproducts[product_id].carb/1000;
              var protein = dietproducts[product_id].protein/1000;
              var fat = dietproducts[product_id].fat/1000;

              if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
                if (typeof(usersides[unix][product_id]) != 'undefined') {
                    var side1 = usersides[unix][product_id].side_id;
                    var side2 = usersides[unix][product_id].op_side_id;
                    
                    var theproduct = dietproducts[product_id];
                    

                    fat = theproduct.fat / 1000;
                    protein = theproduct.protein / 1000;
                    carb = theproduct.carb / 1000;
                    calories = theproduct.calories / 1000;
                    
                    if (typeof(products[side1]) != 'undefined') {
                      fat += products[side1].fat / 1000;
                      protein += products[side1].protein / 1000;
                      carb += products[side1].carb / 1000;
                      calories += products[side1].calories / 1000;
                    }

                    if (typeof(products[side2]) != 'undefined') {
                      fat += products[side2].fat / 1000;
                      protein += products[side2].protein / 1000;
                      carb += products[side2].carb / 1000;
                      calories += products[side2].calories / 1000;
                    }
                }
              }

              sum_calories = sum_calories + calories;
              sum_protein = sum_protein + protein;
              sum_carbs = sum_carbs + carb;
              sum_fat = sum_fat + fat;
            });
          }else if(custom){
            // need to work
          }
      }

    return (
      <div>
      {custom  && (
        Object.keys(mealPlan[unix]).map( (el, idx) => {
          var sum_calories = 0;
          var sum_protein = 0;
          var sum_carbs = 0;
          var sum_fat = 0;
          var bagCount = 0;
          Object.keys(mealPlan[unix][el]).map((el1, idxl) => {
            var product_id = mealPlan[unix][el][el1];
            var calories = dietproducts[product_id].calories/1000;
            var carb = dietproducts[product_id].carb/1000;
            var protein = dietproducts[product_id].protein/1000;
            var fat = dietproducts[product_id].fat/1000;

            if ( (typeof(usersides) != 'undefined') && (typeof(usersides[unix]) != 'undefined') ) {
              if (typeof(usersides[unix][product_id]) != 'undefined') {
                  var side1 = usersides[unix][product_id].side_id;
                  var side2 = usersides[unix][product_id].op_side_id;
                  
                  var theproduct = dietproducts[product_id];
                  

                  fat = theproduct.fat / 1000;
                  protein = theproduct.protein / 1000;
                  carb = theproduct.carb / 1000;
                  calories = theproduct.calories / 1000;
                  
                  if (typeof(products[side1]) != 'undefined') {
                    fat += products[side1].fat / 1000;
                    protein += products[side1].protein / 1000;
                    carb += products[side1].carb / 1000;
                    calories += products[side1].calories / 1000;
                  }

                  if (typeof(products[side2]) != 'undefined') {
                    fat += products[side2].fat / 1000;
                    protein += products[side2].protein / 1000;
                    carb += products[side2].carb / 1000;
                    calories += products[side2].calories / 1000;
                  }
              }
            }

            sum_calories = sum_calories + calories;
            sum_protein = sum_protein + protein;
            sum_carbs = sum_carbs + carb;
            sum_fat = sum_fat + fat;
          })  
          
          if(idx == 0){
            bagCount = 2;
          }else{
            let splitVal = el.split("#");
            bagCount = splitVal[1];
          }
         return( 
          <div className={`day ${xyz ? 'xyz-fin' : 'xyz'} ${'custom-day-delivery'} ${typeEdit ? 'editable-items' : ''} ${buffered ? 'in-buffer' : (inactive ? 'delivered' : '')}`} ref={el => (this.MainDayModRef = el)}>
        <div className='day-header'>
          <h5>Additional Delivery #{bagCount} <span>Total Calories: {this.roundNumber(sum_calories)}, Fat: {this.roundNumber(sum_fat)}g, Protein: {this.roundNumber(sum_protein)}g, CHO: {this.roundNumber(sum_carbs)}g </span></h5>
          <span className="additional-delivery-date">{date && date.format('dddd, Do MMM')}</span>
          {(!inactive && !(unix in skipped)) /* date.isBefore(originTo)) */ &&
          <div className='control-buttons'>
          </div>
          }
        </div>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {this.renderTypeList(isdayactive,bagCount)}
          {this.renderCarousel(breakpoints,4,this.renderCustomProduct(el,isdayactive))}
        </nav>
      </div>
          )
        
      })
      )
    }
    
      {!custom  && 
        <div className={`day ${xyz ? 'xyz-fin' : 'xyz'} ${custom ? 'custom-day-delivery' : ''} ${typeEdit ? 'editable-items' : ''} ${buffered ? 'in-buffer' : (inactive ? 'delivered' : '')}`} ref={el => (this.MainDayModRef = el)}>
        <div className='day-header'>
        <h5>{date && date.format('dddd, Do MMM, YYYY')} <span>Total Calories: {this.roundNumber(sum_calories)}, Fat: {this.roundNumber(sum_fat)}g, Protein: {this.roundNumber(sum_protein)}g, CHO: {this.roundNumber(sum_carbs)}g </span></h5>
          {custom && <span className="additional-delivery-date">{date && date.format('dddd, Do MMM')}</span>}
          {(!inactive && !(unix in skipped)) /* date.isBefore(originTo)) */ &&
          <div className='control-buttons'>
          {!custom && !paused &&
            <ToolTip
              overlayClassName={'on-skip'}
              tooltipDirection={'bottomRight'}
              trigger={['click']}
              tooltipText={<div>
                {!isSkipped && 
                  <Fragment>
                    <div className='regular-bold'>Click to skip this delivery.</div>
                    <div className='t-total'><span className='green'>New delivery will appear at the end of the plan.</span></div>
                    <div className='regular-bold'>Meals for this day will be selected based on the recommendations of a nutritionist.</div>
                    <div className='t-total'><span className='green'>You can change the selection and select the meals you need.</span></div>
                  </Fragment>
                }
                {isSkipped &&
                  <Fragment>
                    <div className='t-total'><span className='green'>Click to resume delivery that day.</span></div>
                  </Fragment>
                }
              </div>}
            >
              <span className='skips'>?</span>
            </ToolTip> }
            {!custom && !paused && <span
              className={isSkipped ? 'button button-skipped' : 'button'}
              onClick={() => this.confirmSkipModal(unix)} //skipTheDay(unix)}//changeSkipped(unix)}
            >
              {isSkipped ? 'Remove from skipped' : 'Skip delivery'}
            </span>}
          </div>
          }
        </div>
        <nav className='top-params-filters w-max-content h-max-content px-0'>
          {this.renderTypeList(isdayactive,'InitialBag')}
          {this.renderCarousel(breakpoints,4,this.renderProduct())}
        </nav>
      </div>
        }
        {activepause && <p className='pauseinfo'>Menu paused from {activepause.date_set &&  moment.unix(activepause.date_set).format('Do MMM, YYYY')} to {activepause.end_date &&  moment.unix(activepause.end_date).format('Do MMM, YYYY')} {!activepause.end_date && 'Infinity'}</p> }
        </div>
    );
  }
}

Day.propTypes = {
  dataArray: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired,
  mealPlan: PropTypes.object.isRequired,
  customMealPlan: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  customPlans: PropTypes.array.isRequired,
  originTo: PropTypes.object.isRequired,
  setMealPlan: PropTypes.func.isRequired,
  setPlanParams: PropTypes.func.isRequired,
  setCustomMealPlan: PropTypes.func.isRequired,
  setCustomPlanParams: PropTypes.func.isRequired,
  changeSkipped: PropTypes.func.isRequired,
  skipped: PropTypes.object.isRequired,
  date: PropTypes.object,
  inactive: PropTypes.bool,
  user: PropTypes.object.isRequired,
  goMenu: PropTypes.func
};

Day.defaultProps = {
  inactive: false
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  mealPlan: state.mealPlan,
  customMealPlan: state.customMealPlan,
  user: state.user,
  planParams: state.planParams,
  customPlans: state.customPlans,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func,
  stop: PropTypes.func
});

const bindAction = dispatch => ({
  setMealPlan: plan => dispatch(setMealPlan(plan)),
  setPlanParams: params => dispatch(setPlanParams(params)),
  setCustomMealPlan: plan => dispatch(setCustomMealPlan(plan)),
  setCustomPlanParams: params => dispatch(setCustomPlanParams(params)),
  setModal: data => dispatch(setModal(data)),
  clearModal: data => dispatch(clearModal(data)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop())
});

export default socketConnect(connect(mapStateToProps, bindAction)(Day));
